import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { HasStore, paginatedResult } from '@application';
import { searchOrthopErrorParams } from '../../domain/dashboard/types/dashboard-manager.types';
import { OrthopError } from '../../domain/dashboard/types/orthopError';
import { DashboardRepository } from '../../repository/dashboard.repository'

export type dashboardManagerState = {
	orthopErrors: paginatedResult<OrthopError[] | null>
};

@Injectable({
	providedIn: 'root',
})
export default class DashboardManager extends HasStore<dashboardManagerState> {
	constructor(
		private readonly dashboardrepository: DashboardRepository,
	) {
		super(
			{
				orthopErrors: {
					data: null,
					pagination: {
						total: 0,
						page: 1,
						limit: 10,
						nbTotalPage: 0,
					},
				},
			},
			'orthop',
		);
	}



	retrieveOrthopErrors(params: searchOrthopErrorParams) {
		return this.dashboardrepository
			.listOrthopErrors(params, this.store.value.orthopErrors.pagination)
			.pipe(take(1))
			.subscribe(result => {
				this.store.update(state => {
					return {
						...state,
						orthopErrors: result,
					};
				});
			});
	}
}
