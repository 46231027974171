import { BaseHttpRepository } from '../../../../../core/infrastructure/src/lib/core/repository/base-http.repository';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { paginatedResult, pagination } from '@application';
import { DTO_orthop, DTO_search_orthop } from '../infrastructure/dashboard/types/DTO_dashboard.types';
import { OrthopErrorAdapter } from '../infrastructure/dashboard/adapters/orthopError.adapter';
import { OrthopErrorSearchAdapter } from '../infrastructure/dashboard/adapters/orthopErrorSearch.adapter';
import { searchOrthopErrorParams } from '../domain/dashboard/types/dashboard-manager.types';
import { OrthopError } from '../domain/dashboard/types/orthopError';

@Injectable({ providedIn: 'root' })
export class DashboardRepository extends BaseHttpRepository {
	constructor(
		private readonly orthopErrorsAdapter: OrthopErrorAdapter,
		private readonly orthopErrorsSearchAdapter: OrthopErrorSearchAdapter,
	) {
		super();
	}

	listOrthopErrors(params: searchOrthopErrorParams, pagination: pagination): Observable<paginatedResult<OrthopError[]>> {
		const dataToSend: DTO_search_orthop = this.orthopErrorsSearchAdapter.adapt(params);

		return this.paginatedGet<DTO_orthop[]>(`${this.apiUrl}Orthop/SynchroErrors`, dataToSend, { 'X-Loader-Key': 'orthop-errors' }).pipe(
			map((errors: paginatedResult<DTO_orthop[]>) => {
				return {
					data: errors.data.map(store => this.orthopErrorsAdapter.adapt(store)),
					pagination: errors.pagination,
				};
			}),
		);
	}
}
